import axios from "axios";

import {
  Api_Login,
  // , admin_token
} from "../../../config/config";

export const Requests_URL = Api_Login + `/api/sales-request/customer/search`;

export const Requests_URL_CREATE =
  Api_Login + "/api/sales-request/customer/create";
export const Requests_URL_DELETE = Api_Login + "/api/sales-request/delete";
export const Requests_URL_APPROVAL = Api_Login + "/api/special-price/approved";
export const Requests_URL_REGECT = Api_Login + "/api/special-price/reject";
export const Requests_URL_UPDATE = Api_Login + "/api/sales-request/update";
export const Requests_URL_GET_ID = Api_Login + "/api/sales-request";
export const Requests_URL_GET = Api_Login + "/api/retail-store/allstores";

// CREATE =>  POST: add a new Request to the server
export function createRequest(Request) {
  return axios.post(
    Requests_URL_CREATE,
    Request,
    // , {headers:{'Content-Type':'multipart/form-data'}},
    // {headers: {
    //   'Authorization': `token ${admin_token}`
    // }}
  );
}

// READ
export function getAllRequests() {
  return axios.get(Requests_URL_GET);
}

export function getRequestById(RequestId) {
  return axios.get(`${Requests_URL_GET_ID}/${RequestId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findRequests(queryParams) {
  //console.log(queryParams);
  // return axios.get(Requests_URL);
  return axios.post(Requests_URL, {
    // "filter": [{"status": "1"},{"approval": 1}],
    filter: [{ status: "1" }],
    sort: "DESC",
    limit: queryParams?.pageSize,
    skip: (queryParams?.pageNumber - 1) * queryParams?.pageSize,
  });
}

// UPDATE => PUT: update the procuct on the server
export function updateRequest(Request, id) {
  //console.log(Request);
  return axios.put(`${Requests_URL_UPDATE}/${id}`, Request);
}

// UPDATE Status
export function updateStatusForRequests(ids, status) {}

// DELETE => delete the Request from the server
export function deleteRequest(RequestId) {
  return axios.delete(`${Requests_URL_DELETE}/${RequestId}`);
}

export function aprovedRequest(RequestId) {
  //console.log(RequestId);
  return axios.put(`${Requests_URL_APPROVAL}/${RequestId}`);
}

export function rejectedRequest(RequestId) {
  return axios.put(`${Requests_URL_REGECT}/${RequestId}`);
}
// DELETE Requests by ids
export function deleteRequests(ids) {
  // return axios.post(`${Requests_URL}/deleteRequests`, { ids });
}
