import axios from "axios";
import { Api_Login } from "../../../config/config";

export const Credits_URL = Api_Login + "/api/retail-sale/customer/search";
export const Credits_CREATE_URL = Api_Login + "/api/retail-sale/create";
export const Credits_DELETE_URL = Api_Login + "/api/retail-sale/delete";
export const Credits_GET_ID_URL = Api_Login + "/api/retail-sale/customer";
export const Credits_UPDATE = Api_Login + "/api/retail-sale/update";
export const Credits_PASSWORD_RESET =
  Api_Login + "/api/sales-Credit/password/update";

// CREATE =>  POST: add a new Credit to the server
export function createCredit(Credit) {
  return axios.post(Credits_CREATE_URL, Credit);
}
// READ
export function getAllCredits() {
  // return axios.get(Credits_URL);
}

export function getCreditById(id) {
  // console.log(id);
  return axios.get(`${Credits_GET_ID_URL}/${id}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCredits(queryParams) {
  // console.log(queryParams.buyback_name);
  if (queryParams.buyback_name === null || queryParams.buyback_name === "") {
    return axios.post(Credits_URL, {
      filter: [{ status: "1" }],
      sort: "DESC",
      limit: queryParams.pageSize,
      skip: (queryParams.pageNumber - 1) * queryParams.pageSize,
    });
  } else {
    return axios.post(Credits_URL, {
      filter: [{ "rstore.store_name": queryParams.buyback_name }],
      sort: "DESC",
      limit: queryParams.pageSize,
      skip: (queryParams.pageNumber - 1) * queryParams.pageSize,
    });
  }
}

// UPDATE => PUT: update the procuct on the server
export function updateCredit(Credit, id) {
  console.log(id);
  return axios.put(`${Credits_UPDATE}/${id}`, Credit);
}

export function updateCreditPassword(Credit, id) {
  console.log(id);
  return axios.put(`${Credits_PASSWORD_RESET}/${id}`, Credit);
}

// UPDATE Status
export function updateStatusForCredits(ids, status) {}

// DELETE => delete the Credit from the server
export function deleteCredit(CreditId) {
  console.log(CreditId);
  return axios.delete(`${Credits_DELETE_URL}/${CreditId}`);
}

// DELETE Credits by ids
export function deleteCredits(ids) {}
