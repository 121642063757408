import axios from "axios";
import { Api_Login } from "../../../config/config";

export const CUSTOMERS_URL = Api_Login + "/api/customer-credit/customer/search";
export const CUSTOMERS_URL_GET_BY_ID =
  Api_Login + "/api/customer-credit/viewone";

// CREATE =>  POST: add a new customer to the server
export function createCustomer(customer) {
  // return axios.post(CUSTOMERS_URL, { customer });
}

// READ
export function getAllCustomers() {
  // return axios.get(CUSTOMERS_URL);
}

export function getCustomerById(customerId) {
  return axios.get(`${CUSTOMERS_URL_GET_BY_ID}/${customerId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCustomers(queryParams) {
  //console.log(queryParams.buyback_name);
  if (queryParams.buyback_name === null || queryParams.buyback_name === "") {
    return axios.post(CUSTOMERS_URL, {
      filter: [{ status: "1" }],
      sort: "DESC",
      limit: queryParams.pageSize,
      skip: (queryParams.pageNumber - 1) * queryParams.pageSize,
    });
  } else {
    return axios.post(CUSTOMERS_URL, {
      filter: [{ "rstore.store_name": queryParams.buyback_name }],
      sort: "DESC",
      limit: queryParams.pageSize,
      skip: (queryParams.pageNumber - 1) * queryParams.pageSize,
    });
  }
}

// UPDATE => PUT: update the customer on the server
export function updateCustomer(customer) {
  // return axios.put(`${CUSTOMERS_URL}/${customer.id}`, { customer });
}

// UPDATE Status
export function updateStatusForCustomers(ids, status) {
  // return axios.post(`${CUSTOMERS_URL}/updateStatusForCustomers`, {
  //   ids,
  //   status
  // });
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId) {
  // return axios.delete(`${CUSTOMERS_URL}/${customerId}`);
}

// DELETE Customers by ids
export function deleteCustomers(ids) {
  // return axios.post(`${CUSTOMERS_URL}/deleteCustomers`, { ids });
}
