/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
// import React from "react";
// import { Dropdown } from "react-bootstrap";
// import { DropdownCustomToggler, DropdownMenu3 } from "../../dropdowns";
import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input2 } from "../../controls";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
// import axios from "axios";
// import {admin_token, Api_Login, Image_Url} from '../../../../app/modules/config/config';
import {
  getShopById,
  updateShop,
} from "../../../../app/pages/_redux/shops/shopsCrud";
import { notify2 } from "../../../../app/config/Toastify";
// import { getUserByToken } from "../../../../app/modules/Auth/_redux/authCrud";

export function ListsWidget10({ className }) {
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  const [data1, setdata1] = useState(user);
  const [loading, setloading] = useState(false);

  const history = useHistory();

  const bodyFormData = new FormData();

  const [store_image, set_Profile_pic] = useState();

  const id = user.retail_store_id;
  useEffect(() => {
    getShopById(id).then((res) => {
      setdata1(res.data);
    });
  }, [id]);

  //console.log(user.retail_store_id);

  // const initShop = {
  //   retail_store_id: undefined,
  //   store_name: "",
  //   store_province: "",
  //   store_district: "",
  //   store_city: "",
  //   store_address: "",
  //   store_cid: "",
  //   store_email: "",
  //   store_phone_1: "",
  //   store_phone_2: "",
  //   store_owner_name: "",
  //   store_owner_nic: "",
  //   store_owner_mobile_1: "",
  //   store_owner_mobile_2: "",
  //   store_owner_email: "",
  //   store_image:"",
  //   store_br_no: "",
  // }

  const ShopEditSchema = Yup.object().shape({
    store_name: Yup.string()
      .required("Name is required")
      .min(2, "Name must be at least 2 characters"),
    // store_province: Yup.string()
    //   .required("Province is required"),
    // store_district: Yup.string()
    //   .required("District is required"),
    store_city: Yup.string().required("City is required"),
    store_address: Yup.string().required("Address is required"),
    store_cid: Yup.string().required("CID is required"),
    store_email: Yup.string()
      .email()
      .required("Email is required"),
    store_phone_1: Yup.string()
      .required("Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Contact number be at least 10 numbers")
      .max(10, "Contact number be at least 10 numbers"),
    // store_phone_2: Yup.string()
    //   .required("Required")
    //   .matches(/^[0-9]+$/, "Must be only digits")
    //   .min(10, "Contact number be at least 10 numbers")
    //   .max(10, "Contact number be at least 10 numbers"),
    store_owner_name: Yup.string()
      .required("Owner Name is required")
      .min(2, "Name must be at least 2 characters"),
    store_owner_nic: Yup.string()
      .required("Owner NIC is required")
      .min(10, "NIC be at least 9 characters"),
    // store_owner_mobile_1: Yup.string()
    //   .required("Required")
    //   .matches(/^[0-9]+$/, "Must be only digits")
    //   .min(10, "Contact number be at least 10 numbers")
    //   .max(10, "Contact number be at least 10 numbers"),
    // store_owner_mobile_2: Yup.string()
    //   .required("Required")
    //   .matches(/^[0-9]+$/, "Must be only digits")
    //   .min(10, "Contact number be at least 10 numbers")
    //   .max(10, "Contact number be at least 10 numbers"),
    // store_owner_email: Yup.string().email()
    //   .required("Owner Email is required"),
    // store_br_no: Yup.string()
    //   .required("Branch is required"),
    // store_image: Yup.string()
    //   .required(),
  });

  return (
    <>
      {notify2()}
      <div
        className={`card card-custom ${className}`}
        style={{ height: "720px" }}>
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Personal Information
          </h3>
        </div>

        {/* Body */}
        <div className="card-body pt-0">
          <Formik
            enableReinitialize={true}
            initialValues={data1}
            validationSchema={ShopEditSchema}
            onSubmit={(values) => {
              //console.log(values);
              bodyFormData.append("store_name", values.store_name);
              bodyFormData.append("store_province", values.store_province);
              bodyFormData.append("store_district", values.store_district);
              bodyFormData.append("store_city", values.store_city);
              bodyFormData.append("store_address", values.store_address);
              bodyFormData.append("store_cid", values.store_cid);
              bodyFormData.append("store_email", values.store_email);
              bodyFormData.append("store_phone_1", values.store_phone_1);
              bodyFormData.append("store_phone_2", values.store_phone_2);
              bodyFormData.append("store_owner_name", values.store_owner_name);
              bodyFormData.append("store_owner_nic", values.store_owner_nic);
              bodyFormData.append(
                "store_owner_mobile_1",
                values.store_owner_mobile_1,
              );
              bodyFormData.append(
                "store_owner_mobile_2",
                values.store_owner_mobile_2,
              );
              bodyFormData.append(
                "store_owner_email",
                values.store_owner_email,
              );
              bodyFormData.append("store_br_no", values.store_br_no);
              bodyFormData.append("store_image", store_image);

              //console.log(bodyFormData);
              setloading(true);
              updateShop(bodyFormData, user.retail_store_id).then((res) => {
                notify2("Retail Store Updated Successfully!!");
                setTimeout(() => {
                  // LoadingDialog({isLoading:true,text:"try"})
                  setloading(false);
                  history.push(`/dashboard`);
                }, 1000);
                // setloading(false)
                // history.push(`/dashboard`)
              });

              // axios({
              //   method: 'put',
              //   baseURL: Api_Login + '/api/retail-store/updates' +`/${user.retail_store_id}`,
              //   data: {
              //     ...bodyFormData
              //   },
              //   headers:{'Content-Type':'multipart/form-data'}
              //   // headers: {'Authorization': `token ${admin_token}`}
              //   })
              //   .then((res) => {
              //     // setShop(res.data.data.results);
              //     console.log(res.data);
              //   })
              //   .catch(function (response) {
              //       console.log(response);
              //   });

              // saveShop(bodyFormData);
              // saveShop(values);
            }}>
            {({ handleSubmit }) => (
              <>
                <div className="card-body pt-0">
                  <Form className="form form-label-right">
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <Field
                          type="text"
                          name="store_name"
                          component={Input2}
                          placeholder="Name"
                          label="Name"
                        />
                      </div>
                      <div className="col-lg-4">
                        <Field
                          type="text"
                          name="store_province"
                          component={Input2}
                          placeholder="Province"
                          label="Province"
                        />
                      </div>
                      <div className="col-lg-4">
                        <Field
                          type="text"
                          name="store_district"
                          component={Input2}
                          placeholder="District"
                          label="District"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <Field
                          type="text"
                          name="store_city"
                          component={Input2}
                          placeholder="City"
                          label="City"
                        />
                      </div>
                      <div className="col-lg-4">
                        <Field
                          type="text"
                          name="store_address"
                          component={Input2}
                          placeholder="Address"
                          label="Address"
                        />
                      </div>
                      <div className="col-lg-4">
                        <Field
                          type="text"
                          name="store_cid"
                          component={Input2}
                          placeholder="CID"
                          label="CID"
                          // customFeedbackLabel="Please enter Price"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <Field
                          name="store_email"
                          component={Input2}
                          placeholder="Email"
                          label="Email"
                        />
                      </div>
                      <div className="col-lg-4">
                        <Field
                          type="text"
                          name="store_phone_1"
                          component={Input2}
                          placeholder="Phone 01"
                          label="Phone 01"
                        />
                      </div>
                      <div className="col-lg-4">
                        <Field
                          type="text"
                          name="store_phone_2"
                          component={Input2}
                          placeholder="Phone 02"
                          label="Phone 02"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <Field
                          type="text"
                          name="store_owner_name"
                          component={Input2}
                          placeholder="Owner Name"
                          label="Owner Name"
                        />
                      </div>
                      <div className="col-lg-4">
                        <Field
                          type="text"
                          name="store_owner_email"
                          component={Input2}
                          placeholder="Owner Email"
                          label="Owner Email"
                        />
                      </div>
                      <div className="col-lg-4">
                        <Field
                          type="text"
                          name="store_owner_nic"
                          component={Input2}
                          placeholder="Owner NIC"
                          label="Owner NIC"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <Field
                          type="text"
                          name="store_owner_mobile_1"
                          component={Input2}
                          placeholder="Mobile 01"
                          label="Mobile 01"
                        />
                      </div>
                      <div className="col-lg-4">
                        <Field
                          type="text"
                          name="store_owner_mobile_2"
                          component={Input2}
                          placeholder="Mobile 02"
                          label="Mobile 02"
                        />
                      </div>
                      <div className="col-lg-4">
                        <Field
                          type="text"
                          name="store_br_no"
                          component={Input2}
                          placeholder="Branch"
                          label="Branch"
                          // customFeedbackLabel="Please enter Price1234"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        {/* <div className="form-group row"> */}
                        <p>Add Profile Pic</p>
                        <input
                          className="agentImageBtn + dropzone-filename"
                          type="file"
                          name="store_image"
                          onChange={(event) => {
                            set_Profile_pic(event.currentTarget.files[0]);
                          }}
                        />
                        {/* </div> */}
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="submit"
                          className="btn btn-primary ml-2 + downbtn">
                          <span>Save</span>
                        </button>
                        {loading && (
                          <span className="ml-3 spinner spinner-red"></span>
                        )}
                      </div>
                    </div>

                    <button
                      type="submit"
                      style={{ display: "none" }}
                      // ref={btnRef}
                      onSubmit={() => handleSubmit()}></button>
                  </Form>
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
