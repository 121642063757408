import axios from "axios";
import { Api_Login } from "../../config/config";

export const LOGIN_URL = Api_Login + `/api/retail-store/customer/login`;
export const REGISTER_URL = Api_Login + `/api/retail-store/registration`;
export const REQUEST_PASSWORD_URL =
  Api_Login + "/api/retail-store/forgetpassword";
export const CHANGE_PASSWORD = Api_Login + `/api/retail-store/changepassword`;
export const ME_URL = Api_Login + `/api/retail-store/customer/by-tocken`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { store_email: email, password: password });
}

export function register(
  store_email,
  store_name,
  store_phone_1,
  password,
  store_address,
  store_city,
) {
  return axios.post(REGISTER_URL, {
    store_email,
    store_name,
    store_phone_1,
    password,
    store_address,
    store_city,
  });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function resetPassword(values) {
  return axios.post(CHANGE_PASSWORD, values);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
