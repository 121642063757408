import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
// import { DashboardPage } from "./pages/DashboardPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage"),
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage"),
);
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );
const ECommercePage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage"),
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage"),
);
const Information = lazy(() => import("./modules/UserProfile/UserProfilePage"));
const Personal = lazy(() => import("./modules/UserProfile/UserProfilePage"));
const Password = lazy(() => import("./modules/UserProfile/UserProfilePage"));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/dashboard" component={UserProfilepage} />
        <Route path="/account-information" component={Information} />
        {/* <Route path="/personal-information" component={Personal} /> */}
        <Route path="/personal-information" component={Personal} />
        <Route path="/change-password" component={Password} />
        {/* <ContentRoute path="/dashboard" component={DashboardPage} /> */}
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/sales" component={ECommercePage} />
        {/* <Route path="/user-profile" component={UserProfilepage} /> */}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
