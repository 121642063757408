export const Api_Login = "https://api.weehenafarms.com";
// export const Api_Login = "http://localhost:3000";
export const Api_Login2 = "https://api.weehenafarms.com/api/admin";

export const Image_Url =
  "https://storage.googleapis.com/weehena-storage-bucket1/";
export const Image_Url1 =
  "https://storage.cloud.google.com/weehena-storage-bucket1/";

// https://www.getpostman.com/collections/269a71c80f0c8ed3d99d
