/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
// import { Dropdown } from "react-bootstrap";
// import { DropdownCustomToggler, DropdownMenu1 } from "../../dropdowns";
// import { toAbsoluteUrl } from "../../../_helpers";
import Card from "react-bootstrap/Card";
// import image from './bg.jpg'
import {
  admin_token,
  Api_Login,
  Image_Url,
} from "../../../../app/modules/config/config";
import axios from "axios";

export function ListsWidget15({ className }) {
  const [shop, setShop] = useState([]);

  useEffect(() => {
    axios({
      method: "post",
      baseURL: Api_Login + "/api/main-stock/retailstore/search",
      data: {
        filter: [{ status: "1" }],
      },
      headers: { Authorization: `token ${admin_token}` },
    })
      .then((res) => {
        setShop(res.data.data.results);
      })
      .catch(function(response) {
        // console.log(response);
      });
    return () => null;
  }, []);

  //   console.log(shop)

  return (
    <>
      {/* begin::List Widget 14 */}
      <div
        className={`card card-custom ${className}`}
        style={{ minHeight: "720px" }}>
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">Products</h3>
          {/* <div className="card-toolbar">
            <Dropdown className="dropdown-inline" alignRight>
              <Dropdown.Toggle
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
              >
                <i className="ki ki-bold-more-ver" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownMenu1 />{" "}
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-1">
          <div className="row">
            {shop.map((item, index) => {
              const img = Image_Url + item.item_image;
              //console.log(img);
              return (
                <div className="col-xl-4" key={index}>
                  <br />
                  <Card style={{ width: "18rem" }}>
                    <Card.Img variant="top" src={img} />
                    {/* <Card.Img variant="top" src={image} /> */}
                    <Card.Body>
                      <Card.Title>{item.item_name}</Card.Title>
                      <Card.Text>{item.mstock_description}</Card.Text>
                      {/* <button variant="primary">Go somewhere</button> */}
                    </Card.Body>
                  </Card>
                </div>
              );

              //
            })}
            {/* <div className="col-xl-4">
                    <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={image} />
                        <Card.Body>
                            <Card.Title>Card Title</Card.Title>
                            <Card.Text>
                            Some quick example text to build on the card title and make up the bulk of
                            the card's content.
                            </Card.Text>
                            </Card.Body>
                    </Card>
                </div>
                <div className="col-xl-4">
                    <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={image} />
                        <Card.Body>
                            <Card.Title>Card Title</Card.Title>
                            <Card.Text>
                            Some quick example text to build on the card title and make up the bulk of
                            the card's content.
                            </Card.Text>
                            </Card.Body>
                    </Card>
                </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
