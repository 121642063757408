export const Api_Login = "https://api.weehenafarms.com";
// export const Api_Login = "http://localhost:3000";
export const Api_Login2 = "https://api.weehenafarms.com/api/admin";

export const Image_Url =
  "https://storage.googleapis.com/weehena-storage-bucket1/";
export const Image_Url1 =
  "https://storage.cloud.google.com/weehena-storage-bucket1/";

export const admin_token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjI4MTE3Yzg4LTBkNjItNDA4ZS1iZmViLTk0MmM5YzYzNmFiYyIsImZuYW1lIjoiRGlhbiIsImxuYW1lIjoiV2VlcmFzZWthcmEiLCJlbWFpbCI6ImRpYW5tYWxha2ExQGdtYWlsLmNvbSIsInJvbGUiOiJhZG1pbiIsImV4cCI6MTYzMDEyODAxMS44NDcsImlhdCI6MTYyNDk0NDAxMX0.UFI_RS4hPgh7i1_OyuFv2zPk9-3EY1RTCP_km9NjpyU";
// https://www.getpostman.com/collections/269a71c80f0c8ed3d99d
