import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notify = (msg) => {
  // toast.success(msg)
  // console.log(msg)
      setTimeout(() => {
        toast.success(msg);
      }, 1200);

    //  window.location.reload()
        return (
          <div>
            {/* <button onClick={notify}>Notify!</button> */}
            <ToastContainer />
          </div>
        );
}

export const notifyError = (msg) => {
 
  setTimeout(() => {
    toast.error(msg);
  }, 1200);

  // setTimeout(() => {
    return (
      <div>
        {/* <button onClick={notify}>Notify!</button> */}
        <ToastContainer />
      </div>
    );
  // }, 2000);
  
  

}

export const notifyWarning = (msg) => {
  // toast.success(msg)
  // console.log(msg)
      // setTimeout(() => {
        toast.warning(msg);
      // }, 1200);

    //  window.location.reload()
        return (
          <div>
            {/* <button onClick={notify}>Notify!</button> */}
            <ToastContainer />
          </div>
        );
}

export const refresPage =()=>{
  window.location.reload()
}