/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { findRequests } from "../../../../app/modules/ECommerce/_redux/Requests/RequestsCrud";
import { Credits_URL } from "../../../../app/pages/_redux/Credit/CreditsCrud";
import { CUSTOMERS_URL } from "../../../../app/pages/_redux/customers/customersCrud";
import { shallowEqual, useSelector } from "react-redux";
import axios from "axios";

export function ListsWidget11({ className }) {
  const user = useSelector(({ auth }) => auth.user, shallowEqual);

  useEffect(() => {
    return () => {};
  }, [user]);

  //console.log(user);

  // const dispatch = useDispatch()
  const [countSales, setcountSales] = useState();
  const [creditCount, setcreditCount] = useState();
  const [saleHistory, setsaleHistory] = useState();
  const [totalAray, settotalAray] = useState([]);
  const [totalcred, settotalcred] = useState();

  useEffect(() => {
    findRequests().then((res) => {
      setcountSales(res.data.data.pagination.count);
    });
  }, []);

  useEffect(() => {
    axios
      .post(Credits_URL, {
        filter: [{ status: "1" }],
      })
      .then((res) => {
        setsaleHistory(res.data.data.pagination.count);
      });
  }, []);

  useEffect(() => {
    axios
      .post(CUSTOMERS_URL, {
        filter: [{ status: "1" }],
      })
      .then((res) => {
        setcreditCount(res.data.data.pagination.count);
        settotalAray(res.data.data.results);

        // dispatch(actions.customersFetched({ totalCount:data.data.pagination.total, entities: data.data.results }));
        // console.log(res.data.data.results)
      });
  }, []);

  useEffect(() => {
    let totalCredit = 0;
    totalAray.forEach((el) => {
      totalCredit += +el.ammount;
    });
    settotalcred(totalCredit);
  }, [totalAray]);

  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: "120px" }}>
        {/* Header */}
        <div className="card-header border-0">
          {/* <h3 className="card-title font-weight-bolder text-dark">Account Complete</h3> */}
        </div>

        {/* Body */}
        <div className="card-body pt-0" style={{ marginTop: "-40px" }}>
          <div className="d-flex align-items-center mb-9 bg-light-success rounded p-5">
            {/* <span className="svg-icon svg-icon-warning mr-5 svg-icon-lg">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
              ></SVG>
            </span> */}

            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a
                href="#"
                className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">
                Account Balance
              </a>
              {/* <span className="text-muted font-weight-bold">Due in 2 Days</span> */}
            </div>

            <span
              className={
                user.due_amount <= 0
                  ? "font-weight-bolder text-success py-1 font-size-lg"
                  : "font-weight-bolder text-danger py-1 font-size-lg"
              }>
              Rs. {user.due_amount.toFixed(2)}
            </span>
          </div>
        </div>
      </div>

      <div
        className={`card card-custom ${className}`}
        style={{ height: "575px" }}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Main Summary
          </h3>
        </div>

        {/* Body */}
        <div className="card-body pt-0">
          <div className="d-flex align-items-center mb-9 bg-light-warning rounded p-5">
            <span className="svg-icon svg-icon-warning mr-5 svg-icon-lg">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}></SVG>
            </span>

            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a
                href="#"
                className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">
                Sale Requests
              </a>
              {/* <span className="text-muted font-weight-bold">Due in 2 Days</span> */}
            </div>

            <span className="font-weight-bolder text-warning py-1 font-size-lg">
              {countSales}
            </span>
          </div>

          <div className="d-flex align-items-center bg-light-info rounded p-5 mb-9">
            <span className="svg-icon svg-icon-info mr-5 svg-icon-lg">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Write.svg",
                )}></SVG>
            </span>
            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a
                href="#"
                className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">
                Payments
              </a>
              {/* <span className="text-muted font-weight-bold">Due in 2 Days</span> */}
            </div>

            <span className="font-weight-bolder text-info py-1 font-size-lg">
              {creditCount}
            </span>
          </div>

          <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-9">
            <span className="svg-icon svg-icon-danger mr-5 svg-icon-lg">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Group-chat.svg",
                )}></SVG>
            </span>

            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a
                href="#"
                className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">
                Sale History
              </a>
              {/* <span className="text-muted font-weight-bold">Due in 2 Days</span> */}
            </div>

            <span className="font-weight-bolder text-danger py-1 font-size-lg">
              {saleHistory}
            </span>
          </div>

          <div className="d-flex align-items-center bg-light-info rounded p-5 mb-9">
            <span className="svg-icon svg-icon-info mr-5 svg-icon-lg">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Write.svg",
                )}></SVG>
            </span>
            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a
                href="#"
                className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">
                Total Payments
              </a>
              {/* <span className="text-muted font-weight-bold">Due in 2 Days</span> */}
            </div>

            <span className="font-weight-bolder text-info py-1 font-size-lg">
              {"Rs. " + (+totalcred).toFixed(2)}
            </span>
          </div>

          {/* <div className="d-flex align-items-center bg-light-info rounded p-5">
            <span className="svg-icon svg-icon-info mr-5 svg-icon-lg">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")}
              ></SVG>
            </span>

            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a
                href="#"
                className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
              >
                Product goals strategy meet-up
              </a>
              <span className="text-muted font-weight-bold">Due in 2 Days</span>
            </div>

            <span className="font-weight-bolder text-info py-1 font-size-lg">
              +8%
            </span>
          </div> */}
        </div>
      </div>
    </>
  );
}
