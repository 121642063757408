/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
// import React from "react";
import React, { useState, useEffect } from "react";
import {
  Credits_URL,
  getCreditById,
} from "../../../../app/pages/_redux/Credit/CreditsCrud";
import { Image_Url } from "../../../../app/modules/config/config";
import axios from "axios";
import { toAbsoluteUrl } from "../../../_helpers";
// import SVG from "react-inlinesvg";

export function ListsWidget14({ className }) {
  const [id, setid] = useState();
  const [array, setarray] = useState([]);

  useEffect(() => {
    axios
      .post(Credits_URL, {
        filter: [{ status: "1" }],
      })
      .then((res) => {
        const le = res.data.data.pagination.count;
        if (le > 0) {
          setid(res.data.data.results[le - 1].sale_id);
        }
      });
  }, []);

  useEffect(() => {
    getCreditById(id).then((res) => {
      //console.log(res.data.saleitems);
      setarray(res.data.saleitems);
    });
  }, [id]);

  return (
    <>
      <div
        className={`card card-custom ${className}`}
        // style={{ height: `${100 + array.length * 80}px` }}
        style={{ minHeight: "720px" }}>
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Last Purchases
          </h3>
        </div>
        <div className="card-body pt-2">
          {array.map((el, index) => {
            return (
              <>
                <div className="d-flex flex-wrap align-items-center mb-10">
                  <div className="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                    <div
                      className="symbol-label"
                      style={{
                        backgroundImage: `url('${toAbsoluteUrl(
                          `${Image_Url + el.itemId.item_image}`,
                        )}')`,
                      }}>
                      {/* <SVG
                      src={toAbsoluteUrl(Image_Url + el.itemId.item_image)}
                      className="h-75 align-self-end"></SVG> */}
                      {/* <img
                      src={Image_Url + el.itemId.item_image}
                      alt={el.retail_sale_id}
                    /> */}
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                    <p>{el.itemId.item_name}</p>
                  </div>
                  <div className="d-flex align-items-center py-lg-0 py-2">
                    <div className="d-flex flex-column text-right">
                      <p>{el.quantity}</p>
                    </div>
                  </div>
                  <div
                    className="col-lg-12"
                    hidden={Boolean(array.length - 1 === index)}
                    style={{ height: "5px" }}>
                    <hr class="new3" />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}
