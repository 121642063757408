// import React, { useState } from "react";
// import objectPath from "object-path";
// import SVG from "react-inlinesvg";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import {
// toAbsoluteUrl,
// checkIsActive } from "../../../_helpers";
// import { useHtmlClassService } from "../../_core/MetronicLayout";
// import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
// import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
// import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
// import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
// import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
// import { QuickUserToggler } from "../extras/QuiclUserToggler";
// import { NavLink } from "react-router-dom";
// import { useLocation } from "react-router";
// import moment from 'moment';

export function Topbar() {
  return null;
  // const uiService = useHtmlClassService();

  // const layoutProps = useMemo(() => {
  //   return {
  //     viewSearchDisplay: objectPath.get(
  //       uiService.config,
  //       "extras.search.display"
  //     ),
  //     viewNotificationsDisplay: objectPath.get(
  //       uiService.config,
  //       "extras.notifications.display"
  //     ),
  //     viewQuickActionsDisplay: objectPath.get(
  //       uiService.config,
  //       "extras.quick-actions.display"
  //     ),
  //     viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
  //     viewQuickPanelDisplay: objectPath.get(
  //       uiService.config,
  //       "extras.quick-panel.display"
  //     ),
  //     viewLanguagesDisplay: objectPath.get(
  //       uiService.config,
  //       "extras.languages.display"
  //     ),
  //     viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
  //   };
  // }, [uiService]);

  // const location = useLocation();
  // const getMenuItemActive = (url) => {
  //     return checkIsActive(location, url) ? "menu-item-active" : "";
  // }

  // const [time1, settime1] = useState(null)
  // let today1
  // setTimeout(() => {
  //   var today = new Date();
  //   const today1 = moment(today).format('MMMM Do YYYY, h:mm:ss a')
  //     // const time1 = moment(today).format("HH:mm")
  //     settime1(today1)
  // }, 1000);
}
