import axios from "axios";

import { Api_Login } from "../../../config/config";

export const Shops_URL = Api_Login + "/api/retail-store/search";

export const Shops_URL_CREATE = Api_Login + "/api/retail-store/register";
export const Shops_URL_DELETE = Api_Login + "/api/retail-store/delete";
export const Shops_URL_UPDATE = Api_Login + "/api/retail-store/updates";
export const Shops_URL_GET_ID = Api_Login + "/api/retail-store/getbyid";
export const Shops_URL_GET = Api_Login + "/api/retail-store/allstores";

// CREATE =>  POST: add a new Shop to the server
export function createShop(Shop) {
  return axios.post(Shops_URL_CREATE, Shop, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

// READ
export function getAllShops() {
  return axios.get(Shops_URL_GET);
}

export function getShopById(ShopId) {
  return axios.get(`${Shops_URL_GET_ID}/${ShopId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findShops(queryParams) {
  if (queryParams.store_name === null || queryParams.store_name === "") {
    return axios.post(Shops_URL, {
      filter: [{ status: "1" }],
      sort: "DESC",
      limit: queryParams.pageSize,
      skip: (queryParams.pageNumber - 1) * queryParams.pageSize,
    });
  } else {
    return axios.post(Shops_URL, {
      filter: [{ store_name: queryParams.store_name }],
      sort: "DESC",
      limit: queryParams.pageSize,
      skip: (queryParams.pageNumber - 1) * queryParams.pageSize,
    });
  }
}

// UPDATE => PUT: update the procuct on the server
export function updateShop(Shop, id) {
  //console.log(id)
  return axios.put(`${Shops_URL_UPDATE}/${id}`, Shop, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

// UPDATE Status
export function updateStatusForShops(ids, status) {}

// DELETE => delete the Shop from the server
export function deleteShop(ShopId) {
  return axios.delete(`${Shops_URL_DELETE}/${ShopId}`);
}

// DELETE Shops by ids
export function deleteShops(ids) {}
