/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import "../../../../_metronic/_assets/sass/customize/login_greeting.scss";
import PasswordReset from "./PasswordReset";

export function AuthPage() {
  const today = new Date().getHours();

  const [toggle, setToggle] = useState(false);

  const morning = () => {
    if (today < 12) {
      //console.log("morning");
      return (
        <div>
          <h1 className="greeting">Good Morning !!!</h1>
          {/* <img  src={toAbsoluteUrl("/media/logos/weehenalogoLogin.png")} /> */}
        </div>
      );
    } else if (today < 16) {
      //console.log("afternoon");
      return (
        <div>
          <h1 className="greeting"> Good Afternoon !!!</h1>
          {/* <img className={login_greeting_image}  src={toAbsoluteUrl("/media/logos/favicon.ico")} /> */}
        </div>
      );
    } else if (today <= 19) {
      //console.log("Evening");
      return (
        <div>
          <h1 className="greeting">Good Evening !!!</h1>
          {/* <img  src={toAbsoluteUrl("/media/logos/weehenalogoLogin.png")} /> */}
        </div>
      );
    }
  };

  useEffect(() => {
    return () => true;
  }, [toggle]);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login">
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-8 p-lg-8"
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/bg/banner_img_dark.jpg",
              )})`,
            }}>
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img
                  alt="Logo"
                  className="max-h-70px"
                  src={toAbsoluteUrl("/media/logos/weehenalogoLogin.png")}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                {morning()}
                <h4 className="wlcmVeera">Welcome to Weehena Client Portal</h4>
                {/* <p className="font-weight-lighter text-white opacity-80">
                  The ultimate Bootstrap & React 16 admin theme framework for
                  next generation web apps.
                </p> */}
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  Copyright &copy; Weehena ERP. Powered by VISION8
                </div>
                {/* <div className="d-flex">
                  <Link to="/terms" className="text-white">
                    Privacy
                  </Link>
                  <Link to="/terms" className="text-white ml-10">
                    Legal
                  </Link>
                  <Link to="/terms" className="text-white ml-10">
                    Contact
                  </Link>
                </div> */}
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            {(window.location.href.split("/").includes("login") ||
              window.location.href.split("/").includes("Logout")) && (
              // "https://weehena-erp-client-portal.web.app/auth/registration"
              <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                <span className="font-weight-bold text-dark-50">
                  Don't have an account yet?
                </span>
                <Link
                  to="/auth/registration"
                  className="font-weight-bold ml-2"
                  id="kt_login_signup"
                  onClick={() => {
                    setToggle(!toggle);
                  }}>
                  Sign Up!
                </Link>
              </div>
            )}
            {window.location.href.split("/").includes("registration") && (
              <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                <span className="font-weight-bold text-dark-50">
                  Have you an account yet?
                </span>
                <Link
                  to="/auth/login"
                  className="font-weight-bold ml-2"
                  id="kt_login_signup"
                  onClick={() => {
                    setToggle(!toggle);
                  }}>
                  {" "}
                  Login!
                </Link>
              </div>
            )}
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/auth/reset-password/:token"
                  component={PasswordReset}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                Copyright &copy; Weehena ERP.{" "}
                <a
                  href="http://vision8.io/"
                  rel="noopener noreferrer"
                  target="_blank">
                  Powered by VISION8
                </a>
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4">
                  Legal
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4">
                  Contact
                </Link>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
